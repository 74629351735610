import { useEffect, useState } from "react";
import htmlToPdfMake from "html-to-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Platform } from "react-native";

export const useDownload = () => {
  // Other platforms don't support pdfMake, and would likely crash the app.
  if (Platform.OS !== "web") return { download: () => {} };

  // Initialization of pdfMake
  pdfMake.vfs = pdfFonts.vfs;
  pdfMake.fonts = {
    Noto_TC: {
      normal:
        "https://fonts.gstatic.com/s/notosanstc/v26/-nF7OG829Oofr2wohFbTp9iFOSsLA_ZJ1g.otf",
      bold: "https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDd1V39Hr7g.otf",
      italics:
        "https://fonts.gstatic.com/s/notosanstc/v26/-nF7OG829Oofr2wohFbTp9iFOSsLA_ZJ1g.otf",
      bolditalics:
        "https://fonts.gstatic.com/s/notosanstc/v26/-nF7OG829Oofr2wohFbTp9iFOSsLA_ZJ1g.otf"
    }
  };

  const [html, setHtml] = useState<string>("");
  const [title, setTitle] = useState<string>("");

  const download = (html: string, title: string) => {
    setTitle(title);
    setHtml(html);
  };

  useEffect(() => {
    if (!html) return;
    const documentDefinition = {
      content: htmlToPdfMake(html),
      defaultStyle: {
        font: "Noto_TC"
      }
    };
    pdfMake.createPdf(documentDefinition).download(title);
    setHtml("");
  }, [html]);

  return { download };
};
